import { MContext } from "./MyProvider";

class ShowMeTab extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      data: ['Listings', 'Listing Types', 'Products', 'Brands', 'Blog'],
      activeTab: '',
    }
  }

  componentDidMount() {
  }

  onClick = e => {
    const currentState = this.state.activeTab;
    this.setState({ activeTab: currentState == e.currentTarget.innerText ? '' : e.currentTarget.innerText });
  }

  render() {
    const {
      onClick,
    } = this;

    var data = this.state.data.map((item) => {
      return (
        <MContext.Consumer>
          {(context) => (
            <div key={item}>
              <button className={context.state.activeTab == item ? 'show-me-active btn btn-default btn-tab' : 'btn btn-default btn-tab'} onClick={() => context.setActiveTab(item)}>{item}</button>
            </div>
          )}
        </MContext.Consumer >
      )
    });


    return (
      <div class='container'>
        <div class='show-me-tab'>
          <h4>Show me</h4>
          <div class="nav-tabs">
            {data}
          </div>

          <hr />
        </div>
      </div>
    )
  }
}
export default ShowMeTab;
