import { MContext } from "./MyProvider";

class ProductResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      myTab: 'Products',
      page: 1,
      isLoading: false,
      lastPage: false,
      results: [],
      search_text: '',
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.results !== this.props.results) {
      this.setState({ results: this.props.results, search_text: this.props.search_text });
    }
  }

  loadMore = () => {
    this.setState((prevState) => ({
      page: prevState.page + 1
    }));

    const { search_text, page } = this.state

    $.ajax({
      url: '/api/v2/search/search_product.json',
      method: "GET",
      data: { q: search_text, page: page },
      dataType: "json",
      success: (response) => {
        console.log(response)
        this.setState((prevState) => ({
          results: [...prevState.results, ...response.data.products],
          lastPage: response.data.meta.last_page,
        }));
      }
    });

  };

  render() {

    const {
      loadMore,
      state: {
        lastPage,
        isLoading,
        results,
      }
    } = this;

    var data = results.map((item) => {
      return (
        <div class="product-item search-result-item" key={item.id}>
          <a class='outside-link' href={item.product_url}>
            <div class="row flex-row">
              <div class="col-md-3 col-sm-12 col-xs-12">
                <div class="left-info">
                  <div class="img-wrap">
                    <img src={item.feature_image_url} />
                  </div>
                  <div class="div-1">Detroit Speed 032084DS</div>
                  <div class="div-2">Part Number: 320-032084DS</div>
                </div>
              </div>
              <div class="col-md-5 col-sm-12 col-xs-12 col-middle">
                <div class="middle-info">
                  <div class="div-1">
                    <h1>{item.name}</h1>
                  </div>
                  <div class="div-3">
                    <div class="text-truncated-5">
                      {item.description}
                    </div>
                  </div>
                  <div class="div-4">
                    <span class='view-detail'>View Details</span>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-12 col-xs-12">
                <div class="right-info">
                  <div class="div-1">${item.price}</div>
                  <div class="div-2">Ships Tomorrow</div>
                  <div class="div-6">
                    <div class="div-7">
                      <input value="1"></input>
                    </div>
                    <div class="div-8">
                      <button class="btn btn-blue-button btn-lg btn-block">Add to Cart</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      )
    });

    return (
      <MContext.Consumer>
        {(context) => (
          !context.state.activeTab || context.state.activeTab === this.state.myTab ?
            (<div class='container pb-section'>
              <h2>Products</h2>
              <p>Matching “{this.props.search_text}”</p>
              <div class="product-result">
                {data}
              </div>

              {!lastPage && results.length > 1 ?
                (<div className="load-more">
                  <button onClick={loadMore} className="btn btn-light-border">
                    {isLoading ? 'Loading...' : 'Load More'}
                  </button>
                </div>) : null
              }

            </div >) : null
        )}
      </MContext.Consumer>
    )
  }

}


export default ProductResult;